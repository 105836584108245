import CN from 'clsx';

import { Box, Text } from '@r-client/shared/ui/core';

import { ReactComponent as LogoSolid } from './r-logo.svg';
import { ReactComponent as LogoOutline } from './r-logo-line.svg';
import { ReactComponent as SpinnerSvg } from './spinner.svg';

import styles from './spinner.module.scss';

export interface ISpinnerProps {
  text?: string;
  theme?: 'default' | 'inverted' | 'gray';
  fullscreen?: boolean;
  centered?: boolean;
  size?: 'default' | 'tiny' | 'medium' | 'large';
  animatedLogo?: 'line' | 'solid';
  animatedSize?: 'tiny' | 'small' | 'regular';
  solidBackground?: boolean;
  opaque?: boolean;
}

export function Spinner({
  text,
  theme = 'default',
  fullscreen,
  centered,
  size = 'default',
  animatedLogo,
  animatedSize = 'tiny',
  solidBackground,
  opaque,
}: ISpinnerProps) {
  const loader = (
    <div
      data-testid="spinner"
      className={CN(styles.spinnerWrapper, {
        [styles.inverted]: theme === 'inverted',
        [styles.gray]: theme === 'gray',
        [styles.fullscreen]: fullscreen,
        [styles.centered]: centered,
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
        [styles.tiny]: size === 'tiny',
        [styles.opaque]: opaque,
      })}
    >
      <Box as="span" m="0 5px 0 0">
        {animatedLogo === 'line' ? (
          <LogoOutline
            className={CN(styles.lineDraw, {
              [styles.tiny]: animatedSize === 'tiny',
              [styles.small]: animatedSize === 'small',
              [styles.regular]: animatedSize === 'regular',
            })}
          />
        ) : animatedLogo === 'solid' ? (
          <LogoSolid
            className={CN(styles.fillAnimate, {
              [styles.tiny]: animatedSize === 'tiny',
              [styles.small]: animatedSize === 'small',
              [styles.regular]: animatedSize === 'regular',
            })}
          />
        ) : (
          <SpinnerSvg className={styles.rotate} />
        )}
      </Box>
      {!!text && (
        <Text as="span" m="0 0 0 8px">
          {text}
        </Text>
      )}
    </div>
  );
  if (fullscreen) {
    return (
      <div
        className={CN(styles.fullscreen, {
          [styles.solidBackground]: solidBackground,
        })}
      >
        {loader}
      </div>
    );
  }
  return loader;
}
